import React from "react";

import { withTranslate } from "../../components/translate";
import Dockington from "../../components/common/Dockington";
import Row from "../../components/common/Row";
import Col from "../../components/common/Col";
import ScrollToTopOnMount from "../../components/common/ScrollToTopOnMount";
import NavBar from "../../components/common/NavBar";
import SEO from "../../components/SEO";

import car from "../../assets/images/car.svg";

import "./ThankYouPage.css";

const ThankYouPage = withTranslate(function ({ translate }) {
    return (
        <div className="thankyou-page">
            <SEO
                title={translate("thankyou.head.title")}
                description={translate("thankyou.head.description")}
                path="/thankyou"
            >
                <meta name="robots" content="noindex" /> {/* Disallow search engines to index this page */}
            </SEO>
            <ScrollToTopOnMount />
            <NavBar brand="Dockington Test Drive" translate={translate} />
            <Row className="px-1" align="center">
                <Col>
                    <header className="container pt-1 mt-4" style={{ textAlign: "center" }}>
                        <img className="car" alt={translate("thankyou.car")} src={car} width={500} height={350} />
                        <div className="tagline">
                            <div className="intro mt-3">
                                <h1>
                                    {translate("thankyou.tagline.title")}
                                </h1>
                                <p className="h2-sub mb-3">
                                    {translate("thankyou.tagline.subtitle").map((part) => (part.dockington ? <Dockington /> : part))}
                                </p>
                            </div>
                        </div>
                    </header>
                    <div className="thankyou-card text-center mb-5 pb-4">
                        <p>{translate("thankyou.card.title")}</p>
                        <p>{translate("thankyou.card.advice").map((part) => (part.dockington ? <Dockington /> : (part.b ? (<b>{part.b}</b>) : part)))}</p>
                        <p className="mt-2">{translate("thankyou.card.greetings")}</p>
                        <p className="mb-2"><b>{translate("thankyou.card.signature")}</b></p>
                        <p className="text-muted">{translate("thankyou.card.ps")}</p>
                    </div>
                </Col>
            </Row>
        </div>
    )
});

export default ThankYouPage;
