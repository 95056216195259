import React from "react"

import Layout from "../components/Layout"
import ThankYouPage from "../views/ThankYouPage"

const ThankYou = ({ location, pageContext: { locale } }) => (
    <Layout location={location} locale={locale}>
        <ThankYouPage />
    </Layout>
);

export default ThankYou;
